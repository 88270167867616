import segmentProps from '../lib/segmentProps';

export default {
    providers: {
        segment: {
            type: 'track',
            name: 'fe_first_time_login',
            props: {
                ...segmentProps,
            },
        },
    },
};
