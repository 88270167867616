import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Text from '@clearscore/rainbow.text';

// eslint-disable-next-line postcss-modules/no-unused-class
import styles from './index.module.css';

function InfoPanel({ language, type }) {
    const { heading, content } = language;

    return (
        <div className={cx(styles.container, [styles.container], { [styles[type]]: type })}>
            <Text.H2>{heading}</Text.H2>
            <div className={styles.content}>
                <Text.Body1>{content}</Text.Body1>
            </div>
        </div>
    );
}

InfoPanel.propTypes = {
    type: PropTypes.oneOf(['success', 'warning', 'error', 'standard']),
    language: PropTypes.object,
};

InfoPanel.defaultProps = {
    type: 'success',
    language: {},
};

export default InfoPanel;
