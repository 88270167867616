import hasClientData from '@clearscore/helpers.has-client-data';

import clients from './clients';
import clientDirectory from './client-directory';
import handleErroneousResponseInterceptor from './lib/handle-erroneous-response-interceptor';
import getAuth from './lib/auth';

/**
 * Add client instance to payload meta object
 * @param {object} store redux store
 * @param {object} action redux action
 * @returns {boolean} if action structure is correct
 */
function addClient(store, action) {
    const {
        payload: {
            request: { client, meta: requestMeta },
        },
    } = action;

    const meta = {
        ...action.payload.meta,
        client: clients(client, store, requestMeta),
    };
    const headers = {
        ...action.payload.request.headers,
        ...getAuth(client, store),
    };

    return {
        ...action,
        payload: {
            ...action.payload,
            meta,
            request: {
                ...action.payload.request,
                headers,
            },
        },
    };
}

/**
 * If the action payload has a client object, add the correct client instance.
 * If no client matches, fallback to the default client
 * @param {object} store redux store
 * @param {object} action redux action
 * @returns {object} action redux action
 */

function addClientInstance(store, action) {
    return hasClientData(action) ? addClient(store, action) : action;
}

export const allClients = clients;
export { getAuth, clientDirectory, handleErroneousResponseInterceptor };
export default addClientInstance;
