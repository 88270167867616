import axios from 'axios';
import canUseDom from '@clearscore/helpers.can-use-dom';
import { getEntry, setEntry } from '@clearscore/helpers.cache';

import clientDirectory from './client-directory';
import handleErroneousResponseInterceptor from './lib/handle-erroneous-response-interceptor';
import getInterpolatedUrl from './lib/get-interpolated-url';

const CACHE_KEY = 'http_clients';

const buildClients = (store, requestMeta) =>
    Object.keys(clientDirectory).reduce((prev, clientName) => {
        const client = axios.create(clientDirectory[clientName]);
        client.interceptors.response.use(
            (response) => response,
            async (error) => handleErroneousResponseInterceptor(error, store, client, requestMeta),
        );
        return { ...prev, [clientName]: client };
    }, {});

const UNKNOWN_CLIENT_ERROR = (client) =>
    `ERROR [add-client-instance]: Unknown client '${client}'.
     Please check allowed clients in @clearscore-group/ui.external-cs-auth.add-client-instance`;

export default (clientKey, store, requestMeta) => {
    const state = store.getState();
    if (canUseDom() && !getEntry(CACHE_KEY)) {
        setEntry(CACHE_KEY, buildClients(store, requestMeta));
    }
    const client = clientDirectory[clientKey];
    const clientConfiguration = getEntry(CACHE_KEY)?.[clientKey];
    if (!client || !clientConfiguration) throw Error(UNKNOWN_CLIENT_ERROR(clientKey));

    clientConfiguration.defaults.baseURL = getInterpolatedUrl(client, state, requestMeta);
    return clientConfiguration;
};
