/**
 * State filter helper, reduces down state to match only filter keys
 * @param filter
 * @param object
 * @return {{}}
 */
function reduceState(filter = {}, object = {}) {
    return Object.keys(filter).reduce((state, key) => {
        if (!object[key]) return state;
        return {
            ...state,
            [key]: object[key],
        };
    }, {});
}

/**
 * Reduce initial state to match reducer
 * @param reducer
 * @param state
 * @return {{entities: {}}}
 */
export default function initialStateFilter(reducer, state = {}) {
    const initialState = reduceState(reducer, state);
    if (reducer.entities) initialState.entities = reduceState(reducer.entities, state.entities);
    return initialState;
}
