/* eslint-disable camelcase */
import { defer } from 'rxjs';
import deepmerge from 'deepmerge';
import addClientInstance from '@clearscore-group/ui.external-cs-auth.add-client-instance';
import addUrlVariables from '@clearscore/helpers.add-url-variables';

function createRequestAction(store, action) {
    const addVars = addUrlVariables(store);

    return addVars(addClientInstance(store, action));
}

function fromRequestAction({ payload: { request = {}, meta } }, extendRequest) {
    if (extendRequest) return defer(() => meta.client(deepmerge(request, extendRequest)));
    return defer(() => meta.client(request));
}

/**
 * Check if action has correct structure in payload required for middleware
 * @param {object} action redux action
 * @returns {boolean} if action structure is correct
 */
function hasData(action) {
    return action?.payload?.request?.headers && action?.payload?.request?.url && action?.payload?.meta;
}

/**
 * Perform API request
 * @param {object} store redux store
 * @param {function} next next middleware func
 * @param {object} action redux action
 * @returns {void}
 */
function handleRequest(store, next, requestAction) {
    // dispatch original action type to reducer before attempting request
    next(requestAction);

    const {
        payload: { request = {} },
    } = requestAction;

    const dispatchSuccess = (response, requestData) =>
        store.dispatch({
            type: `${requestAction.type}_SUCCESS`,
            payload: response.data,
            requestData: requestData.data,
            requestMeta: requestData.meta,
            statusCode: response.status,
        });

    // eslint-disable-next-line arrow-body-style
    const dispatchError = (res = {}, requestData) => {
        // console.log(res, requestData)
        return store.dispatch({
            type: `${requestAction.type}_ERROR`,
            payload: res.response ? res.response : res,
            requestMeta: requestData.meta,
            statusCode: res.response?.status,
        });
    };

    const oAuthRequest$ = fromRequestAction(requestAction);

    oAuthRequest$.subscribe(
        (response) => dispatchSuccess(response, request),
        (response) => dispatchError(response, request),
    );
}

/**
 * If action has correct signature, perform API request.
 * Dispatch success or error actions
 * @param {object} store redux store
 * @returns {Function} middleware
 */
const requestMiddleware = (store) => (next) => (action) => {
    // add request data from helpers
    const requestAction = createRequestAction(store, action);
    return hasData(requestAction) ? handleRequest(store, next, requestAction) : next(requestAction);
};

export default requestMiddleware;
