import interpolateWithMarket from '@clearscore/helpers.interpolate-with-market';

const getInterpolatedUrl = (client, state, requestMeta) => {
    const isFunction = (config) => typeof config === 'function';
    const baseURL = isFunction(client.baseURL) ? client.baseURL(state) : client.baseURL;

    return baseURL
        ? interpolateWithMarket({
              state,
              url: baseURL,
              market: requestMeta ? requestMeta.market : null,
          })
        : null;
};

export default getInterpolatedUrl;
