import { EN_GB, EN_AU, EN_ZA } from '@clearscore/config.i18n';

export default {
    [EN_GB]: {
        heading: 'Oops! Something went wrong...',
        content: 'We are sorry but it seems we have had a problem trying to load this page.',
    },
    [EN_ZA]: {
        heading: 'Oops! Something went wrong...',
        content: 'We are sorry but it seems we have had a problem trying to load this page.',
    },
    [EN_AU]: {
        heading: 'Oops! Something went wrong...',
        content: 'We are sorry but it seems we have had a problem trying to load this page.',
    },
};
