import { types as identityTypes } from '@clearscore/redux.identity';
import { types as productsTypes } from '@clearscore/redux.products';
import { types as dashboardTypes } from '@clearscore/redux.dashboard';

import { FIRST_TIME_LOGIN, PRODUCT_APPLY_CLICK, SCORE_BAND_RECEIVED } from './events';

export default {
    [identityTypes.IDENTITY_FIRST_TIME_REGISTRATION]: [FIRST_TIME_LOGIN],
    [productsTypes.PRODUCTS_APPLY_CLICK]: [PRODUCT_APPLY_CLICK],
    [dashboardTypes.DASHBOARD_FETCH_SUCCESS]: [SCORE_BAND_RECEIVED],
};
