import { GB, ZA } from '@clearscore/config.i18n';

const marketConfig = {
    [GB]: {
        accountHref: '/my-account/account-and-security',
        freeTextEnabled: false,
    },
    [ZA]: {
        accountHref: '/my-account/account-and-security',
        freeTextEnabled: true,
    },
};

export default marketConfig;
