import React, { Component } from 'react';
import PropTypes from 'prop-types';
import monitoring from '@clearscore/helpers.monitoring';

import ErrorMessage from '../message';
import lang from '../../lib/lang';

class VerticalError extends Component {
    state = { hasError: false };

    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true });
        if (monitoring.hasMonitoring()) {
            monitoring.withScope((scope) => {
                scope.setExtras(errorInfo);
                Sentry.captureException(error);
            });
        }
    }

    render() {
        const language = lang[this.props.locale];
        if (this.state.hasError) return <ErrorMessage language={language} />;
        return this.props.children;
    }
}

VerticalError.propTypes = {
    children: PropTypes.node.isRequired,
    locale: PropTypes.string,
};

VerticalError.defaultProps = {
    locale: 'en-gb',
};

export default VerticalError;
