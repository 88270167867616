import getDevice from '@clearscore/helpers.device';
import getEnv from '@clearscore/helpers.envs';
import { getSessionToken } from '@clearscore/helpers.session';
import monitoring from '@clearscore/helpers.monitoring';

const UNKNOWN_CLIENT_ERROR = (client) =>
    `ERROR [add-auth-headers]: Unknown client '${client}'.
     Please check allowed clients in @clearscore-group/ui.external-cs-auth.add-client-instance`;

/**
 * add device header to api calls
 * @param store
 * @return {{"X-CS-Device"}}
 * @private
 */
function addDeviceHeader() {
    return {
        'X-CS-Device': getDevice(),
    };
}

/**
 * Get auth and device
 * @param {object} store redux store
 * @returns {object} headers based on session and device
 */
function getFluxHeaders() {
    const sessionToken = getSessionToken();
    return {
        ...(sessionToken && { Authorization: `Bearer ${sessionToken}` }), // Must be conditional to avoid breaking nova
        ...addDeviceHeader(),
    };
}

/**
 * Get auth headers required for Banking API request (needs auth)
 * @param {object} store redux store
 * @returns {object} auth headers
 */
function getBankingAuth() {
    return {
        Authorization: `Bearer ${getSessionToken()}`,
    };
}

/**
 * Get auth headers required for Caesium API requests using bearer token
 * @param {object} store redux store
 * @returns {object} auth headers
 */
function getCaesiumBearerAuth() {
    const deviceHeader = addDeviceHeader();
    if (!deviceHeader['X-CS-Device']) monitoring.log('No device attached in the header');
    return {
        Authorization: `Bearer ${getSessionToken()}`,
        ...addDeviceHeader(),
    };
}

function getRenderEngineApiKey() {
    return {
        'x-api-key': getEnv('RENDER_ENGINE_API_KEY'),
    };
}

/**
 * Get auth headers required for Caesium API request
 * @param {string} client api client to match auth headers for
 * @param {object} store redux store
 * @returns {object} auth headers
 */
function getAuth(client) {
    switch (client) {
        case 'banking':
        case 'bankingTl':
            return getBankingAuth();
        case 'gbService':
        case 'international':
        case 'internationalBlob':
        case 'internationalText':
            return getCaesiumBearerAuth();
        case 'flux':
            return getFluxHeaders();
        case 'staticDescriptors':
        case 'media':
        case 'connectUpload':
        case 'uploadS3Policy':
        case 'connect':
        case 'products':
            return {};
        case 'renderEngine':
            return getRenderEngineApiKey();
        default:
            throw Error(UNKNOWN_CLIENT_ERROR(client));
    }
}

export default getAuth;
