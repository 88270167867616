import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Header from '@clearscore/rainbow.header';
import Text from '@clearscore/rainbow.text';
import Container from '@clearscore/rainbow.container';
import Button from '@clearscore/rainbow.button';
import { useSelector } from 'react-redux';
import MenuSecondaryReturn from '@clearscore/rainbow.menu-secondary-return';
import ToggleSwitch from '@clearscore/rainbow.toggle-switch';
import { selectors as routeSelectors } from '@clearscore/redux.routes';
import { EN_GB } from '@clearscore/config.i18n';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import TextareaInput from '@clearscore/rainbow.textarea';
import Spacer from '@clearscore/rainbow.spacer';
import useMarketConfig from '@clearscore/hooks.use-market-config';

import styles from './my-account-delete-account.module.css';
import marketConfig from './lib/market-config';
import { FREE_TEXT_INPUT_NAME, PAGE_NAMESPACE } from './lib/constants';
import page from './copy/pages/my-account-delete-account/en_gb/copy.json';
import useSaveAnswers from './hooks/use-save-answers';

const DeleteAccount = ({ routeProps: { isRegistration } }) => {
    const { i18n, t } = useTranslation(PAGE_NAMESPACE);

    i18n.addResourceBundle(EN_GB, PAGE_NAMESPACE, page);
    const { fetch, isLoading, submitAction } = useSaveAnswers();
    const { accountHref, freeTextEnabled } = useMarketConfig(marketConfig);
    const [isDisabled, setIsDisabled] = useState(true);
    const regHref = useSelector(routeSelectors.getRouteByIDVStatus)?.route?.path;
    const returnHref = isRegistration ? regHref : accountHref;
    const returnText = isRegistration ? t('registrationLinkText') : t('accountLinkText');
    const componentStyles = cx(styles.component, {
        [styles.registration]: isRegistration,
    });

    return (
        <section className={componentStyles} data-qa="my-account-delete-account">
            <MenuSecondaryReturn linkText={returnText} href={returnHref} />
            <Header title={t('heading')} description={t('subheading')} />

            <Container theme={Container.Theme.TRANSPARENT} size={Container.Size.SMALL} isFullHeight>
                <div className={styles.contentWrapper}>
                    <div className={styles.headerWrapper}>
                        <Text.H2>{t('title')}</Text.H2>
                    </div>

                    <div>
                        {Object.entries(t('content'))
                            .sort(([a], [b]) => Number(a) - Number(b))
                            .map(([, line]) => (
                                <div key={line} className={styles.textWrapper}>
                                    <Text.Body2>{line}</Text.Body2>
                                </div>
                            ))}
                    </div>

                    <div className={styles.confirmationToggleWrapper}>
                        <ToggleSwitch>
                            <ToggleSwitch.Label>{t('toggleLabel')}</ToggleSwitch.Label>
                            <ToggleSwitch.Switch onChange={(isChecked) => setIsDisabled(!isChecked)} />
                        </ToggleSwitch>
                    </div>

                    <Form
                        name="my-account-delete-account-form"
                        data-qa="my-account-delete-account-form"
                        onSubmit={submitAction(t)}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} className={styles.formWrapper}>
                                {freeTextEnabled && !isDisabled && (
                                    <div className={styles.textareaInputWrapper}>
                                        <Spacer all={{ top: Spacer.spacings.SMALL }}>
                                            <label htmlFor={FREE_TEXT_INPUT_NAME}>
                                                <Text.H3>{t('deletionReasonPrompt')}</Text.H3>
                                            </label>
                                            <br />
                                            <Field type="textarea" name={FREE_TEXT_INPUT_NAME}>
                                                {({ input }) => (
                                                    <TextareaInput
                                                        onChange={input.onChange}
                                                        value={input.value}
                                                        maxLength={10000}
                                                        data-qa="delete-account-form-textarea-input"
                                                        id={input.name}
                                                    />
                                                )}
                                            </Field>
                                        </Spacer>
                                    </div>
                                )}

                                <Button
                                    type={Button.types.PRIMARY}
                                    size={Button.sizes.LARGE}
                                    theme={Button.themes.LIGHT}
                                    htmlType="submit"
                                    isDisabled={isDisabled}
                                    isLoading={isLoading || fetch.isPending}
                                    isNarrow
                                >
                                    {t('deleteAccount')}
                                </Button>
                            </form>
                        )}
                    />
                </div>
            </Container>
        </section>
    );
};
DeleteAccount.propTypes = {
    routeProps: PropTypes.shape({ isRegistration: PropTypes.bool }),
};

DeleteAccount.defaultProps = {
    routeProps: {
        isRegistration: false,
    },
};

export default DeleteAccount;
