import axios from 'axios';
import {
    getPublisherCookieUTM,
    publisherCookieIsSentToBackend,
    markPublisherCookieAsSentToBackend,
    updateSessionCookie,
} from '@clearscore/helpers.cookies';
import getEnv from '@clearscore/helpers.envs';
import fingerprint from '@clearscore/helpers.device-fingerprinting';
import { getStructuredData } from '@clearscore/helpers.session';
import { getAnonymousId } from '@clearscore/helpers.segment';
import { actions as sessionActions } from '@clearscore/redux.session';
import logout from '@clearscore-group/ui.external-cs-auth.logout';

import clientDirectory from '../client-directory';
import getInterpolatedUrl from './get-interpolated-url';

const TIMEOUT_STATUS_CODES = [401, 403];
let isRefreshing = false;
let requestQueue = [];

const parseJwt = (token) =>
    JSON.parse(
        decodeURIComponent(
            window
                .atob(token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/'))
                .split('')
                .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
                .join(''),
        ),
    );

/**
 * Get the extra payload to add to requests containing the
 * user's UTM data (if any, and not sent already), and
 * mark cookie as "sent" if found so we don't send again
 */
const getMarketingIntentPayload = () => {
    const utmParams = getPublisherCookieUTM();

    if (Object.entries(utmParams).length > 0 && !publisherCookieIsSentToBackend(utmParams)) {
        markPublisherCookieAsSentToBackend(utmParams);

        return {
            utm_parameters: utmParams,
        };
    }

    return {};
};

export const clearRequestState = () => {
    requestQueue = [];
    isRefreshing = false;
};

const getAuthToken = async (isLogout, store, requestMeta) => {
    try {
        const { authToken, refreshToken } = getStructuredData();
        const baseURL = getInterpolatedUrl(clientDirectory.international, store.getState(), requestMeta);

        return await axios.post(`${baseURL}/login-service/v3/token`, {
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            client_type: 'internal',
            client_id: getEnv('OAUTH_CLIENT_ID'),
            device_id: parseJwt(authToken)['device-id'] || fingerprint.hash,
            session_id: getAnonymousId(),
            ...getMarketingIntentPayload(),
        });
    } catch (error) {
        clearRequestState();
        store.dispatch(sessionActions.logout());
        return Promise.reject(error);
    }
};

const addToRequestQueue = (cb) => {
    requestQueue.push(cb);
};

const onRefreshed = (token) => {
    requestQueue.map((cb) => cb(token));
    clearRequestState();
};

export default async (error, store, client, requestMeta, isLogout) => {
    if (error.response?.status === 503) {
        store.dispatch({ type: 'CS/MAINTENANCE_MODE_ENABLED' });
        return Promise.reject(error);
    }
    if (TIMEOUT_STATUS_CODES.includes(error.response?.status)) {
        const originalRequest = error.config;

        if (originalRequest._retry) {
            if (!isLogout) await logout(store.getState(), store, requestMeta);
            clearRequestState();
            return Promise.reject(error);
        }

        originalRequest._retry = true;

        const retryRequest = new Promise((resolve) =>
            addToRequestQueue((token) => {
                originalRequest.headers.Authorization = `Bearer ${token}`;
                resolve(client(originalRequest));
            }),
        );

        if (!isRefreshing) {
            isRefreshing = true;
            try {
                const { data: authData } = await getAuthToken(isLogout, store, requestMeta);
                isRefreshing = false;
                updateSessionCookie({
                    access_token: authData.access_token,
                    refresh_token: authData.refresh_token,
                });
                store.dispatch(
                    sessionActions.setNativeSession({
                        access_token: authData.access_token,
                        refresh_token: authData.refresh_token,
                        refreshToken: authData.refresh_token,
                    }),
                );
                onRefreshed(authData.access_token);
            } catch (e) {
                return Promise.reject(error);
            }
        }
        return retryRequest;
    }

    return Promise.reject(error);
};
