import getEnv from '@clearscore/helpers.envs';

export default {
    media: {
        baseURL: getEnv('MEDIA_API_DOMAIN'),
        responseType: 'json',
    },
    banking: {
        baseURL: getEnv('OB_API_DOMAIN'),
        responseType: 'json',
    },
    bankingTl: {
        baseURL: getEnv('OB_TL_API_DOMAIN'),
        responseType: 'json',
    },
    uploadS3Policy: {
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: 'json',
    },
    staticDescriptors: {
        baseURL: getEnv('STATIC_JSON_URL'),
        responseType: 'json',
    },
    international: {
        baseURL: getEnv('INTERNATIONAL_API_DOMAIN'),
        responseType: 'json',
    },
    internationalBlob: {
        baseURL: getEnv('INTERNATIONAL_API_DOMAIN'),
        responseType: 'blob',
    },
    internationalText: {
        baseURL: getEnv('INTERNATIONAL_API_DOMAIN'),
        responseType: 'text',
    },
    flux: {
        baseURL: getEnv('FLUX_API_DOMAIN'),
        responseType: 'json',
    },
    gbService: {
        baseURL: getEnv('GB_SERVICE_DOMAIN'),
        responseType: 'json',
    },
    connect: {
        baseURL: getEnv('CONNECT_API_DOMAIN'),
        responseType: 'json',
    },
    connectUpload: {
        headers: {
            'Content-Disposition': 'attachment',
        },
    },
    products: {
        baseURL: getEnv('PRODUCTS_API_DOMAIN'),
        responseType: 'json',
    },
    renderEngine: {
        baseURL: getEnv('RENDER_ENGINE_API_DOMAIN'),
        responseType: 'json',
    },
};
