/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import configureStore, { Provider, browserHistory } from '@clearscore/redux.store';
import { resolveProp } from '@clearscore/helpers.resolve-props';

import VerticalError from './components/error';
import trimState from './lib/trim-state';
import insertIfExists from './lib/insert-if-exists';

const createVerticalStoreFactory =
    ({ name, reducer = {}, middleware = [], epics = null, appBridgeTypes = [], verticalMiddleware, reducers }) =>
    ({ dispatch, initialState, coreAppBridgeTypes, history }) => {
        const allReducers = reducers(reducer);
        return configureStore({
            initialState: trimState(allReducers, initialState),
            reducers: allReducers,
            name,
            coreDispatch: dispatch,
            pageMiddleWare: [...insertIfExists(verticalMiddleware(name)), ...insertIfExists(middleware)],
            pageEpics: epics,
            appBridgeTypes: [...insertIfExists(coreAppBridgeTypes), ...insertIfExists(appBridgeTypes)],
            history,
        });
    };

// TODO: remove vertical level appBridgeTypes when ALL apps have migrated to use @scaffolding/core-app
const withVerticalFactory =
    (verticalMiddleware, reducers) =>
    (Component, { name, reducer = {}, middleware = [], epics = null, appBridgeTypes = [] }) => {
        const createVerticalStore = createVerticalStoreFactory({
            name,
            reducer,
            middleware,
            epics,
            appBridgeTypes,
            verticalMiddleware,
            reducers,
        });

        const WithVerticalStateful = (props) => {
            const { children, routeConfig: { id, routeProps = {} } = {}, ...restProps } = props;
            const [store, setStore] = useState();
            const [history, setHistory] = useState();

            useEffect(() => {
                const { dispatch, coreAppBridgeTypes, initialState, history: _history = browserHistory() } = props;
                const _store = createVerticalStore({ dispatch, initialState, coreAppBridgeTypes, history: _history });

                setStore(_store);
                setHistory(_history);
            }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

            if (!store) return null;

            return (
                <Provider store={store}>
                    <Component {...restProps} routeProps={routeProps}>
                        {typeof children === 'function' ? children({ store, history }) : children || null}
                    </Component>
                </Provider>
            );
        };

        return ({ initialState = {}, dispatch, ...props }) => (
            <VerticalError locale={resolveProp('market.activeLocale', initialState)}>
                <WithVerticalStateful initialState={initialState} dispatch={dispatch} {...props} />
            </VerticalError>
        );
    };

export default withVerticalFactory;
