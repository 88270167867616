const KEY_LOGOUT_MESSAGE = 'CS_LOGOUT_MESSAGE';

export const setLogoutMessage = (message) => window.localStorage?.setItem(KEY_LOGOUT_MESSAGE, message);

export const clearLogoutMessage = () => window.localStorage?.removeItem(KEY_LOGOUT_MESSAGE);

export const getLogoutMessage = ({ clear = false } = {}) => {
    const message = window.localStorage?.getItem(KEY_LOGOUT_MESSAGE);

    if (clear) clearLogoutMessage();

    return message;
};
