import axios from 'axios';
import { actions as sessionActions } from '@clearscore/redux.session';
import {
    clientDirectory,
    getAuth,
    handleErroneousResponseInterceptor,
} from '@clearscore-group/ui.external-cs-auth.add-client-instance';
import interpolateWithMarket from '@clearscore/helpers.interpolate-with-market';
import monitoring from '@clearscore/helpers.monitoring';
import branch from '@clearscore/helpers.branch';
import { getQueryClient } from '@shared/helpers.query-client';

const BEARER_FORMAT = new RegExp(/((Bearer)\s[\w-]*\.[\w-]*\.[\w-]*$)/);

const getInterpolatedUrl = (baseURL, state, requestMeta) => {
    const isFunction = (config) => typeof config === 'function';

    const url = isFunction(baseURL) ? baseURL(state) : baseURL;

    return baseURL
        ? interpolateWithMarket({
              state,
              url,
              market: requestMeta ? requestMeta.market : null,
          })
        : null;
};

const branchLogout = () => {
    try {
        branch.logout();
    } catch (e) {
        monitoring.log(`Error logging out from branch ${e.message}`);
    }
};

const clearUserSession = async (store, instance, headers) => {
    branchLogout();
    if (BEARER_FORMAT.test(headers.Authorization)) {
        try {
            await instance.post('/login-service/v3/logout', {}, { headers });
        } catch (e) {
            monitoring.log('Error when trying to logout via the login-service');
        }
    }
    const queryClient = getQueryClient();
    if (queryClient) {
        queryClient.clear();
    }

    if (store) return store.dispatch(sessionActions.logout());
    return sessionActions.logout();
};

export default async (state, store, requestMeta, isDeleteAccount = false) => {
    if (isDeleteAccount) {
        branchLogout();
        if (store) return store.dispatch(sessionActions.logout());
        return sessionActions.logout();
    }
    const headers = getAuth('international');
    const config = {
        ...clientDirectory.international,
        baseURL: getInterpolatedUrl(clientDirectory.international.baseURL, state, requestMeta),
    };
    const instance = axios.create(config);
    instance.interceptors.response.use(
        (response) => response,
        async (error) => handleErroneousResponseInterceptor(error, store, instance, requestMeta, true),
    );

    return clearUserSession(store, instance, headers);
};

export * from './message';
export * from './theme';
