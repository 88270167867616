import { reducers as session } from '@clearscore/redux.session';
import { reducers as routes } from '@clearscore/redux.routes';
import { reducers as profile } from '@clearscore/redux.profile';
import { reducers as market } from '@clearscore/redux.market';

export default function combine({ entities, ...reducers }) {
    return {
        entities: {
            ...routes.entities,
            ...entities,
        },
        market: market.domain,
        session: session.domain,
        profile: profile.domain,
        ...reducers,
    };
}
