import { useState } from 'react';
import { answers as answersConfig } from '@clearscore/service-config.user-insights';
import useCsAuthMutateQuery from '@clearscore-group/ui.external-cs-auth.cs-mutate-query';
import { selectors as marketSelectors } from '@clearscore/redux.market';
import { actions as profileActions, selectors as profileSelectors } from '@clearscore/redux.profile';
import { useDispatch, useSelector, useStore } from 'react-redux';
import logout, { setLogoutMessage } from '@clearscore-group/ui.external-cs-auth.logout';
import useMarketConfig from '@clearscore/hooks.use-market-config';

import { FREE_TEXT_INPUT_NAME, SKIP_REASON_ID } from '../lib/constants';
import marketConfig from '../lib/market-config';

const useSaveAnswers = () => {
    const [successMessage, setSuccessMessage] = useState('');
    const { fetch } = useSelector(marketSelectors.getPredicate);
    const { freeTextEnabled } = useMarketConfig(marketConfig);
    const { mutate, isLoading } = useCsAuthMutateQuery({ ...answersConfig, method: 'post' });
    const dispatch = useDispatch();
    const store = useStore();
    const { remove } = useSelector(profileSelectors.getPredicate);

    if (remove.isComplete) {
        setLogoutMessage(successMessage);
        logout(store.getState(), store, null, true);
    }

    const isNotBlank = (str) => !(!str || /^\s*$/.test(str) || str.length < 2);

    const completeForm = (data) => {
        mutate(data, {
            // Error or success... doesn't matter!
            onSettled: () => {
                dispatch(profileActions.erase());
            },
        });
    };

    const submitAction = (t) => (formData) => {
        setSuccessMessage(t('successMessage'));
        if (freeTextEnabled) {
            const freeTextAnswer = formData[FREE_TEXT_INPUT_NAME];
            const isAnswerSupplied = isNotBlank(freeTextAnswer);
            const payload = {
                questionId: 'delete-reason-question-id',
                answer: {
                    raw: isAnswerSupplied ? 'delete-reason-other' : SKIP_REASON_ID,
                    freeText: isAnswerSupplied ? `${t('deleteAccountOtherReason')}: ${freeTextAnswer}` : SKIP_REASON_ID,
                    questionVariant: 'delete-reason-question-id',
                },
            };
            completeForm(payload);
        } else {
            dispatch(profileActions.erase());
        }
    };

    return { fetch, isLoading, submitAction };
};

export default useSaveAnswers;
