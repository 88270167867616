import segmentProps from '../lib/segmentProps';

export default {
    providers: {
        segment: {
            type: 'track',
            name: 'fe_product_apply_clicked',
            props: {
                ...segmentProps,
                category: '#context.category',
                label: '#context.label',
                scoreBand: '?#context.scoreBand',
                isNewUser: '?#context.isNewUser',
                hero_rating: '?#context.productHeroRating',
            },
        },
    },
};
