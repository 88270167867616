import React from 'react';
import PropTypes from 'prop-types';

import InfoPanel from '../info-panel';
import styles from '../error/index.module.css';

const ErrorMessage = (props) => (
    <div className={styles.errorContainer} data-qa="vertical-error-catch">
        <InfoPanel {...props} type="standard" />
    </div>
);

ErrorMessage.propTypes = {
    language: PropTypes.object.isRequired,
};

export default ErrorMessage;
