import { getAuth, allClients } from '@clearscore-group/ui.external-cs-auth.add-client-instance';
import csMutateQuery from '@clearscore/hooks.use-cs-mutate-query';
import { useMutation } from 'react-query';
import { useStore } from 'react-redux';

const useAuthCSMutateQuery = ({ client, endpoint, headers, method, params, options }) => {
    const store = useStore();
    const authHeaders = getAuth(client);
    const clientInstance = allClients(client, store, null);

    return useMutation(
        (data) =>
            csMutateQuery({
                client: clientInstance,
                endpoint,
                data,
                headers: { ...headers, ...authHeaders },
                method,
                params,
            }),
        options,
    );
};

export default useAuthCSMutateQuery;
